html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
}

body {
  background-color: #000000;
  color: #E0E0E0;
  font-family: Arial, sans-serif;
}

li {
  margin-left: 20px;
}

.bullet-list li {
  list-style-type: disc;
}

.no-bullets li {
  list-style-type: none;
}

.content-container {
  width: 75%;
  max-width: 75%;
  padding: 20px;
  overflow-y: auto;
  height: 100vh;
}

.control-button {
  width: 80px;
  height: 80px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  padding: 8px;
}

.control-button .icon {
  font-size: 30px;
  margin-bottom: 5px;
}

.control-button .label {
  font-size: 12px;
  text-align: center;
}

.mic-button { background-color: #3a59b5; }
.video-button { background-color: #52914a; }
.stop-button { background-color: #c59ad1e6; }


p, h1, h2, h3, h4 {
  margin-bottom: 2em;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 1; }
  40% { opacity: 1; }
  60% { opacity: 1; }
}

.fadeInOut {
  animation: fadeInOut 6s ease-in-out infinite;
}

/* Spinning Arc Animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.relative {
  isolation: isolate;
}

/* Custom Tooltip Styles */
.custom-tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(65, 105, 225, 0.9);
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.group:hover .custom-tooltip {
  opacity: 1;
}

.custom-tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(65, 105, 225, 0.9) transparent transparent transparent;
}

@keyframes logoPulse {
  0% {
    filter: drop-shadow(0 0 0 hsl(0, 0%, 18%));
  }
  65% {
    filter: drop-shadow(0 0 10px hsl(206, 30%, 44%));
  }
  100% {
    filter: drop-shadow(0 0 0 hsl(0, 0%, 18%));
  }
}

.logo {
  height: 160px;
  width: auto;
  animation: logoPulse 4s infinite;
}


/* 
@keyframes fadeInOut {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
} */

.callout {
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(65, 105, 225, 0.9);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid rgba(255, 255, 255, 0.9);
  white-space: nowrap;
  animation: fadeInOut 3s ease-in-out infinite;
}

.callout-pointer {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #c59ad1e6;
}



.App {
  display: flex;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}




header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: fixed; /* Make header fixed */
  top: 0;
  left: 0;
  width: 100%;
  background-color: #000000; /* Match the body background color */
  z-index: 1000;
}

main {
  display: flex;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
}

.persona-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  position: sticky;
  top: 0;
  height: 100vh;
  overflow-y: auto;
}




.controls-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.timer-container {
  margin-bottom: 1rem;
}

.timer-pill {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 9999px; /* Full pill shape */
  font-size: 1rem;
}

.audio-recorder-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.persona-image-container {
  position: relative;
  margin-top: 20px; /* Add some top margin */
}

.bg-image {
  background-size: cover;
  background-position: center;
  position: absolute;
  inset: 0;
}

.start-button {
  background-color: transparent;
  border: 1px solid #4169E1;
  color: #4169E1;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 16px;
}

.start-button:hover {
  background-color: #4169E1;
  color: #E0E0E0;
}

.bg-blue-royal {
  background-color: #4169E1;
}

.rounded-t-md {
  border-top-left-radius: 0.375rem; /* 6px */
  border-top-right-radius: 0.375rem; /* 6px */
}

.timer {
  color: #4169E1;
  font-size: 18px;
  margin: 10px 0;
}

.internal-monologue {
  color: #808080;
  font-style: italic;
  margin-top: 20px;
}

/* Speed Dial positioning */
.MuiSpeedDial-root {
  position: fixed;
  top: 100px; /* Adjust as needed to position below the fixed header */
  right: 16px; /* Adjust as needed */
  z-index: 1100; /* Ensure it appears above other elements */
}

/* Sidebar Styles */
.sidebar {
  position: fixed;
  top: 0;
  right: -70%;
  width: 70%;
  height: 100%;
  background-color: black; /* Updated background color */
  transition: right 0.3s ease-in-out;
  color: #E0E0E0;
  z-index: 1000;
  overflow-y: auto;
  border-top-left-radius: 8px; /* Rounded top left corner */
  border-bottom-left-radius: 8px; /* Rounded bottom left corner */
  border-left: 1px solid #4b4947; /* Thin grey border */
}

.sidebar.open {
  right: 0;
}

.sidebar-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #4b4947; /* Updated background color */
  position: relative;
  top: 0;
  width: 100%;
  border-top-left-radius: 8px; /* Ensure header is rounded on top left corner */
}

.sidebar-header h2 {
  margin: 0;
  color: rgb(255, 255, 255);
  font-size: large;
}

.close-btn {
  background: none;
  border: none;
  color: #E0E0E0;
  font-size: 24px;
  cursor: pointer;
}

.sidebar-body {
  padding: 20px;
  background-color: black; /* Match the sidebar's background color */
  flex-grow: 1;
  overflow-y: auto;
  padding-top: 60px; /* Ensure space for the header */
}

/* Ensure main content shifts when sidebar is open */
.App.sidebar-open {
  margin-right: 70%;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    right: -100%;
  }

  .App.sidebar-open {
    margin-right: 0;
  }
}

.livebox {
  max-width: 500px;
  background-color: #000000;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 8px 26px; /* Ensure consistent padding */
  display: flex;
  border-radius: 8px 8px 8px 8px;
}

/* Additional styles for the text content */
.box {
  background-color: white; /* Background color for the text content */
}

.text-black {
  color: black; /* Text color for the content */
}

.goldilocks-box {
  margin-bottom: 24px;
}

.goldilocks-box .box {
  padding: 0;
}

.goldilocks-box h5 {
  background-color: #535eb4; /* Royal blue background for headers */
  color: white; /* White text for headers */
  padding: 8px 16px; /* Ensure consistent padding */
  margin: 0; /* Remove margin to fix alignment */
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center; /* Ensure vertical alignment */
}
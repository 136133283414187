.mentor-report {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.generate-button, .download-button {
  background-color: #4169E1;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 20px;
  transition: background-color 0.3s;
}

.generate-button:hover, .download-button:hover {
  background-color: #0056b3;
}

.generate-button:disabled {
  background-color: #b87a9b;
  cursor: not-allowed;
}

.generate-button.pulsing {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.error-message {
  color: #dc3545;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f8d7da;
  border-radius: 5px;
}

/* Progress indicator styles */
.progress-container {
  margin: 20px 0;
  width: 100%;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.05);
}

.progress-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.progress-title {
  font-weight: bold;
  color: #2c3e50;
}

.progress-time {
  font-family: monospace;
  background-color: #e9ecef;
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: bold;
  color: #495057;
}

.progress-bar {
  height: 20px;
  background-color: #e9ecef;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
}

.progress-fill {
  height: 100%;
  background-color: #4169E1;
  transition: width 0.5s ease;
  border-radius: 10px;
}

.progress-stage {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #6c757d;
}

.progress-stage-main {
  display: flex;
  justify-content: space-between;
}

.progress-context {
  font-style: italic;
  font-size: 12px;
  margin-top: 5px;
  color: #6c757d;
  padding-left: 5px;
  border-left: 3px solid #e9ecef;
}

.timestamp {
  font-style: italic;
}

.report-title {
  color: #343a40;
  text-align: center;
  margin-bottom: 20px;
}

.accordion-section {
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.accordion-title {
  width: 100%;
  padding: 15px;
  background-color: #f8f9fa;
  border: none;
  text-align: left;
  font-size: 1.1em;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s;
}

.accordion-title:hover {
  background-color: #e9ecef;
}

.accordion-icon {
  transition: transform 0.3s ease;
}

.accordion-section.open .accordion-icon {
  transform: rotate(180deg);
}

.accordion-content {
  padding: 15px;
  background-color: white;
  border-top: 1px solid #e9ecef;
  display: none;
}

.accordion-section.open .accordion-content {
  display: block;
}

ul {
  list-style: none;
  padding-left: 0;
  margin: 1em 0;
}


li:last-child:after {
  display: none;
}

.medilist-container {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
  }
  
  .medilist-form {
    color: #e0e0e0;
  }
  
  .form-title {
    color: #ffd700;
    font-size: 24px;
    margin-bottom: 16px;
  }
  
  .form-note {
    color: #bdbdbd;
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  .form-section {
    margin-bottom: 30px;
  }
  
  .section-title {
    color: #ffd700;
    font-size: 20px;
    margin-bottom: 12px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    color: #e0e0e0;
  }
  
  .form-input,
  .form-textarea {
    width: 100%;
    padding: 8px;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid #616161;
    border-radius: 4px;
    color: #ffffff;
    font-size: 16px;
  }
  
  .form-textarea {
    min-height: 60px;
  }
  
  .medication-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
  }
  
  .medication-table th {
    text-align: left;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffd700;
  }
  
  .medication-table td {
    padding: 5px;
  }
  
  .btn {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .btn-add {
    background-color: #4CAF50;
    color: white;
    margin-top: 10px;
  }
  
  .btn-add:hover {
    background-color: #45a049;
  }
  
  .btn-submit {
    background-color: #2196F3;
    color: white;
    margin-top: 20px;
  }
  
  .btn-submit:hover {
    background-color: #0b7dda;
  }
  
  .btn-submit.submitting {
    background-color: #f44336;
  }
  
  .feedback {
    margin-top: 20px;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    color: #ffffff;
  }

  /* New style for info text */
  .info-text {
    color: #ffca28;
    font-size: 16px;
    font-style: italic;
    text-align: center;
    margin-top: 20px;
    padding: 10px;
    background-color: rgba(255, 205, 40, 0.1);
    border-radius: 4px;
    border-left: 3px solid #ffca28;
  }